import React from "react";
import "../css/CsFooter.css";

function CsFooter() {
  return (
    <div className="FooterBody">
        <div className="FooterTitle">
          <p>CS Senior Design Projects</p>
        </div>

        <div className="FooterText">
          <p>
            This page seeks to give students a place to access past projects in order to help them succeed in their class.
            <br/>
            Projects from 2016 to present are archieved here for everyone to access.
          </p>
        </div>
    </div>
  );
}

export default CsFooter;
